import { createStyles, makeStyles, Theme } from "@material-ui/core";

const useStyles = makeStyles(({ spacing }: Theme) => {
  return createStyles({
    statement: {
      letterSpacing: 1,
    },
    quoteContainer: {
      marginBottom: spacing(4),
      display: "flex",
      flexDirection: "column",
      position: 'relative',
    }
  })
});

export default useStyles;