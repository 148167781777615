import * as React from "react";
import Layout from "../layouts/Layout";

import { Detail } from "../components/Give";

const Give = () => {
  return (
    <Layout title="Give">
      <div style={{ marginTop: 80 }} />
      <Detail />
    </Layout>
  );
};

export default Give;
