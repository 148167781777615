import { Container, Paper, Typography } from "@material-ui/core";
import { Link } from "gatsby";
import * as React from "react";
import useStyles from "./Detail.styles";

const Detail = () => {
  const classes = useStyles();

  return (
    <Container style={{ padding: "32px 8px 64px 8px", minHeight: 480 }}>
      <Typography variant="h4" style={{ color: "#353b48", marginBottom: 32 }}>
        Giving
      </Typography>
      <Typography className={classes.statement} style={{ marginBottom: 36 }}>
        We believe that giving is an act of worship! Throughout scripture we see
        God’s people generously and sacrificially giving not out of compulsion,
        but in response to what God has done for us through Jesus. Contributions
        to Lifehouse Church are stewarded by our leadership team to equip,
        strengthen, and advance the vision of multiplying disciples, the church,
        and city groups throughout East London.
      </Typography>
      <div className={classes.quoteContainer}>
        <Typography className={classes.statement} style={{ marginBottom: 8 }}>
          <sup>6</sup> Remember this — a farmer who plants only a few seeds will
          get a small crop. But the one who plants generously will get a
          generous crop. <sup>7</sup> You must each decide in your heart how
          much to give. And don’t give reluctantly or in response to pressure.
          “For God loves a person who gives cheerfully.” <sup>8</sup> And God
          will generously provide all you need. Then you will always have
          everything you need and plenty left over to share with others.
        </Typography>
        <Link
          className={classes.statement}
          style={{
            textAlign: "right",
            paddingRight: 36,
          }}
          target="_blank"
          to="https://www.bible.com/bible/116/2CO.9.6-8.NLT"
        >
          2 Corinthians 9: 6 - 8
        </Link>
      </div>
      <Paper style={{ border: "1px solid #ccc", padding: 16 }}>
        <Typography className={classes.statement}>Lifehouse Church</Typography>
        <Typography className={classes.statement}>
          First National Bank
        </Typography>
        <Typography className={classes.statement}>
          Account No: 567 1113 2161
        </Typography>
        <Typography className={classes.statement}>
          Branch Code: 210221
        </Typography>
      </Paper>
    </Container>
  );
};

export default Detail;
